<template>
  <div class="handbook-block">
    <div class="category">
      <!-- <img :src="icon" style="margin-right: 12px" /> -->
      <img :src="icons[theme][icon]" style="margin-right: 12px">
      <p>{{ get(handbookCategoryConfig[category], 'name') }}</p>
    </div>
    <el-divider />
    <div class="link-list">
      <div
        v-for="link in links"
        :key="link.url"
        class="link-item"
        @click="getHandBookFileDownloadLink(link.id)"
      >
        <div class="download-icon">
          <img src="../assets/file_download.svg">
        </div>
        <p class="link-label">{{ link.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { GetHandBookFileDownloadLink } from '@/api/homePage'
import { handbookCategoryConfig } from '@/config/homePage'
import { downloadURI } from '@/utils/helper'
import { get } from 'lodash'

import beautyAppointment from '../assets/beauty/appointment.svg'
import beautyEcommerce from '../assets/beauty/ecommerce.svg'
import beautyLine from '../assets/beauty/line.svg'
import beautyMember from '../assets/beauty/member.svg'

import ohbotAppointment from '../assets/ohbot/appointment.svg'
import ohbotEcommerce from '../assets/ohbot/ecommerce.svg'
import ohbotLine from '../assets/ohbot/line.svg'
import ohbotMember from '../assets/ohbot/member.svg'

import righttimeAppointment from '../assets/rightTime/appointment.svg'
import righttimeEcommerce from '../assets/rightTime/ecommerce.svg'
import righttimeLine from '../assets/rightTime/line.svg'
import righttimeMember from '../assets/rightTime/member.svg'

import wishmobileAppointment from '../assets/wishmobile/appointment.svg'
import wishmobileEcommerce from '../assets/wishmobile/ecommerce.svg'
import wishmobileLine from '../assets/wishmobile/line.svg'
import wishmobileMember from '../assets/wishmobile/member.svg'

export default {
  name: 'HandbookBlock',
  props: {
    icon: String,
    category: String,
    links: Array,
  },
  data: () => ({
    handbookCategoryConfig,
    icons: {
      beauty: {
        appointment: beautyAppointment,
        ecommerce: beautyEcommerce,
        line: beautyLine,
        member: beautyMember,
      },
      ohbot: {
        appointment: ohbotAppointment,
        ecommerce: ohbotEcommerce,
        line: ohbotLine,
        member: ohbotMember,
      },
      righttime: {
        appointment: righttimeAppointment,
        ecommerce: righttimeEcommerce,
        line: righttimeLine,
        member: righttimeMember,
      },
      wishmobile: {
        appointment: wishmobileAppointment,
        ecommerce: wishmobileEcommerce,
        line: wishmobileLine,
        member: wishmobileMember,
      },
    }
  }),
  computed: {
    theme() {
      return get(this.$store.state, 'app.appTheme') || 'ohbot'
    }
  },
  methods: {
    get,
    async getHandBookFileDownloadLink (id) {
      const [res, err] = await GetHandBookFileDownloadLink({ id })
      if (err) {
        this.$message.error(err)
        return
      }
      downloadURI(res.url)
    },
  },
}
</script>

<style lang="scss" scoped>
.handbook-block {
  @apply bg-white rounded-sm;
  @apply py-[16px] px-[12px];
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.category {
  @apply text-[16px] font-medium px-[10px] flex items-center gap-[12px];
}
.link-list {
  @apply flex flex-col gap-[12px] px-[10px];
}

.link-item {
  @apply flex items-center cursor-pointer h-[38px];
  @apply border border-primary-100 rounded-[6px];
}

.download-icon {
  @apply bg-primary-100 flex justify-center items-center w-[36px] h-full flex-shrink-0;
}
.link-label {
  @apply px-[12px] truncate text-[14px];
}
</style>
