<template>
  <div class="handbook-list">
    <!-- <HomeBtn /> -->
    <PageTitle
      title="教學手冊"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.go(-1)"
    />

    <div class="block-list" style="gap: 28px">
      <HandBookBlock
        v-if="handbookList.filter((i) => i.type === 'line_setting').length"
        category="line_setting"
        icon="line"
        :links="handbookList.filter((i) => i.type === 'line_setting')"
      />
      <HandBookBlock
        v-if="handbookList.filter((i) => i.type === 'member_setting').length"
        category="member_setting"
        icon="member"
        :links="handbookList.filter((i) => i.type === 'member_setting')"
      />
      <HandBookBlock
        v-if="handbookList.filter((i) => i.type === 'appointment_setting').length"
        category="appointment_setting"
        icon="appointment"
        :links="handbookList.filter((i) => i.type === 'appointment_setting')"
      />
      <HandBookBlock
        v-if="handbookList.filter((i) => i.type === 'ecommerce_setting').length"
        category="ecommerce_setting"
        icon="ecommerce"
        :links="handbookList.filter((i) => i.type === 'ecommerce_setting')"
      />
    </div>
  </div>
</template>

<script>
// import HomeBtn from './HomeBtn.vue'
import HandBookBlock from './HandbookBlock.vue'
import { GetHandbook } from '@/api/homePage'
import lineIcon from '../assets/line.svg'
import memberIcon from '../assets/member.svg'
import appointmentIcon from '../assets/appointment.svg'
import ecommerceIcon from '../assets/ecommerce.svg'

export default {
  name: 'HandbookList',
  components: { HandBookBlock },
  data: () => ({
    lineIcon,
    memberIcon,
    appointmentIcon,
    ecommerceIcon,
    handbookList: [],
  }),
  async mounted() {
    await this.getHandbook()
  },
  methods: {
    async getHandbook() {
      const [res, err] = await GetHandbook({})
      if (err) {
        this.$message.error(err)
        return
      }
      this.handbookList = res
    },
  },
}
</script>

<style lang="scss" scoped>
.handbook-list {
  @apply relative;
}
.block-list {
  @apply grid;
  grid-template-columns: 284px 284px;
}
</style>
